<template>
<div>
  <!-- Select Event -->
  <div class="m-3 pb-5">
    <p>Welcome back to {{ $app.productName }}!</p>
    <div v-if="events.length > 0">
      <p>Events you're participating to:</p>
      <event-list
        :events="events"
        :user="user"
        />
    </div>
    <b-alert variant="info" :show="true">
      To join a {{ $app.eventName.toLowerCase() }}, please use an invite link from your host.
    </b-alert>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("events-signup");
import eventList from '@/components/eventList.vue';
import { db } from "@/services/db";
import { getCollectionDocsFromIds } from "@/services/dbutils.js";

export default {
  components: { eventList },
  data() {
    return {
      events: [],
    };
  },
  computed: {
    user() { return this.$parent.user; }
  },
  async mounted() {
    if (this.user.eventRegistrations) { // Only registered events
      log.log("events from user.eventRegistrations", this.user.eventRegistrations);
      this.events = await getCollectionDocsFromIds(db.collection("LiveEvents"), this.user.eventRegistrations);
    }
  }
}
</script>

<style>

</style>